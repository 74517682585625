
import { Card } from 'components/Form/Card';
import Heading from 'components/Form/Heading';
import Row from 'components/Form/Row';
import colors from 'styles/colors';

const cardStyles = `
  small {
    margin-top: 1rem;
    opacity: 0.5;
    display: block;
    a { color: ${colors.primary}; }
  }
`;

const DnsServerCard = (props: {data: any, title: string, actionButtons: any }): JSX.Element => {
  const dnsSecurity = props.data;
  return (
    <Card heading={props.title} actionButtons={props.actionButtons} styles={cardStyles}>
      {dnsSecurity.dns.map((dns: any, index: number) => {
        return (<>
          { dnsSecurity.dns.length > 1 && <Heading as="h4" size="small" color={colors.primary}>DNS Server #{index+1}</Heading> }
          <Row lbl="IP Address" val={dns.address} key={`ip-${index}`} />
          { dns.hostname && <Row lbl="Hostname" val={dns.hostname}  key={`host-${index}`} /> }
          <Row lbl="DoH Support" val={dns.dohDirectSupports ? '✅ Ja*' : '❌ Nein*'} key={`doh-${index}`} />
        </>);
      })}
      {dnsSecurity.dns.length > 0 && (<small>
        *
        Die Unterstützung von DoH wird durch die Antwort des DNS-Servers auf eine DoH-Anfrage bestimmt. Manchmal führt dies zu false positives und es ist auch möglich, dass der DNS-Server DoH unterstützt, aber nicht auf DoH-Anfragen antwortet. Wenn der DNS-Server DoH nicht unterstützt, kann es dennoch möglich sein, DoH durch die Verwendung eines DoH-Proxys zu nutzen.
      </small>)}
    </Card>
  );
}

export default DnsServerCard;