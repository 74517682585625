
const colors = {
  primary: '#d20000',
  primaryLighter: '#d82222',
  textColor: '#ffffff',
  textColorSecondary: '#a4b1cd',
  background: '#141d2b',
  backgroundDarker: '#000000',
  backgroundLighter: '#000000',
  bgShadowColor: '#0f1620',
  fgShadowColor: '#900000',
  primaryTransparent: '#d20000',

  // Action Colors
  info: '#04e4f4',
  success: '#20e253',
  warning: '#f6f000',
  error: '#fca016',
  danger: '#f80363',
  neutral: '#272f4d',
};

export default colors;